html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: system-ui;
  background-color: #253237;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}