$app-primary-color: #D8D8D8; // Feel free to change the color
$app-secondary-color: #717070; // Feel free to change the color

.app {
    text-align: center;
    font-family: 'Arial', sans-serif;

    &-header {
        background-color: #D8D8D8dd;
        color: $app-secondary-color;
        padding: 20px;
        border-radius: 8px;
        margin: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        h1 {
            margin: 0 0 20px 0;
        }
    }

    .connect-now-btn {
        background-color: $app-secondary-color;
        color: $app-primary-color;
        border: 2px solid $app-primary-color;
        padding: 10px 20px;
        border-radius: 50px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background-color: $app-primary-color;
            color: $app-secondary-color;
        }
    }
}